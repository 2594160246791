var errorMsg;
var baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
var apiCall;
var localStorageTableNames = [];
var localStorageTableNamesAllowed = [];
var tempConfig = {};

const localeStorageTableDefaultConfig = {
    'page': 1,
    'limit': default_per_page,
    'search': '',
    'status': {
        'new': 0,
        'low': 0,
        'offline': 0,
        'deleted': 0,
        'view': '',
        'orderingMethod': ''
    },
    'order': {}
};

(function () {

    if ( typeof window.CustomEvent === "function" ) return false;

    function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();
$( document ).ready(function() {
    errorMsg = translate['There was an error. Please try again.'];
    if (userLogged && user_company)
        getHeaderNotificationErrorsDropdown();
    
    $('[data-toggle="tooltip"]').tooltip();
    $(".orderable a").on('click', function(e){
        var order;
        var direction;
        var config = $(this).parentsUntil('table').parent().data('config');
        if($(this).hasClass('sort-up')){
            $("#"+pageConfig[config].table+" .sort").each(function(){
                $(this).removeClass('sort-up').removeClass('sort-down');
            });
            $(this).removeClass('sort-up');
            $(this).addClass('sort-down');
            direction = 'desc';

        } else{
            $("#"+pageConfig[config].table+" .sort").each(function(){
                $(this).removeClass('sort-up').removeClass('sort-down');
            });
            $(this).removeClass('sort-down');
            $(this).addClass('sort-up');
            direction = 'asc';
        }

        if($(this).parent().data('name')){
            order = $(this).parent().data('name');
            pageConfig[config].page = 1;
            pageConfig[config].order = {};
            pageConfig[config].order[order] = direction;

            let existingLocalStorageTableNames = JSON.parse(localStorage.getItem("localStorageTableNames"));
            if (existingLocalStorageTableNames && typeof existingLocalStorageTableNames[config] !== 'undefined') {
                existingLocalStorageTableNames[config].page = 1;
                existingLocalStorageTableNames[config].limit = default_per_page;
                existingLocalStorageTableNames[config].order = {};
                existingLocalStorageTableNames[config].order[order] = direction;
            } else {
                existingLocalStorageTableNames = {};
                existingLocalStorageTableNames[config] = localeStorageTableDefaultConfig
            }

            localStorage.setItem('localStorageTableNames', JSON.stringify(existingLocalStorageTableNames));

        }
        $("#"+pageConfig[config].table+" tbody").html('');
        getApiData(config);
        var event = new CustomEvent('order');
        window.dispatchEvent(event);
    });


	$( ".datepicker" ).datepicker();

           //chosen select
    $('.chosenSelect').chosen({
        width: '100%',
        no_results_text: translate["No results match"]
    });

    $(document).on('click', '.logout-button', function(){
        window.location = baseUrl + 'logout';
    });

	$(document).on('click', '.popup-close, .cancel-button', function(){
	    $(this).closest('.popup-wrapp').hide();
        $('.overlay').hide();
    });

	$(document).on('click', '.logout-link', function(){
        $('#logout_popup').show();
        $('.overlay').show();
    });

    $("#search-dealers").on('input', function(){

        var value = $(this).val();

        $("table").find("tr").each(function(index) {
            if (!index) return;
            var customer = $(this).find("td").first().text().toUpperCase();
            $(this).toggle(customer.indexOf(value) !== -1);
        });
    });

    $(document).on('click', '.non-reporting-sort', function(e){

        if(e.handled !== true) {
            col = 2;
            var reverse = 0;
            if ($(this).hasClass('sort-up')) {
                reverse = 1;
                $(this).removeClass('sort-up');
                $(this).addClass('sort-down');
            } else if ($(this).hasClass('sort-down')){
                reverse = 0;
                $(this).removeClass('sort-down');
                $(this).addClass('sort-up');
            }

            var table = $('.dealersTable')[0];
            var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
                tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
                i;
            reverse = -((+reverse) || -1);
            tr = tr.sort(function (a, b) { // sort rows
                return reverse // `-1 *` if want opposite order
                    * (a.cells[col].textContent.trim() // using `.textContent.trim()` for test
                            .localeCompare(b.cells[col].textContent.trim())
                    );
            });
            for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
            e.handled = true;
        }
    });

    $(document).on('click', '.custmers-sort', function(e){

        if(e.handled !== true) {
            col = 0;
            var reverse = 0;
            if ($(this).hasClass('sort-up')) {
                reverse = 1;
                $(this).removeClass('sort-up');
                $(this).addClass('sort-down');
            } else if ($(this).hasClass('sort-down')){
                reverse = 0;
                $(this).removeClass('sort-down');
                $(this).addClass('sort-up');
            }

            var table = $('.dealersTable')[0];
            var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
                tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
                i;
            reverse = -((+reverse) || -1);
            tr = tr.sort(function (a, b) { // sort rows
                return reverse // `-1 *` if want opposite order
                    * (a.cells[col].textContent.trim() // using `.textContent.trim()` for test
                            .localeCompare(b.cells[col].textContent.trim())
                    );
            });
            for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
            e.handled = true;
        }
    });

    $("#search-icon").on('click', function(){
        // $("#search-form").removeClass('hidden').animate({
        //     opacity: 1
        // }, 500);
        $("#search-form").removeClass('hidden');
        $("#search-form").find('input').select().focus();
        $(this).addClass('hidden');
        if($(window).width() > 992){
            $(".navbar-right .user").hide();
        }
    });
    $("#main").on('click', function () {
        $("#search-form").addClass('hidden');
        $("#search-icon").removeClass('hidden');
        if($(window).width() > 992){
            $(".navbar-right .user").show();
        }

    });


    $(".js-switch-link").on('click', function(e){

        e.preventDefault();
        var message = translate['You are about to change your current company status. Are you sure you want to proceed?'];

        if($(this).data('id')){
            adminSwitch.company = parseInt($(this).data('id'));
        }
        adminSwitch.openPopup(message);

    });

    $("#confirm_switch_admin .js-confirm").on('click', function (e) {
        if(!user_company || user_company.ID !== adminSwitch.company){
            adminSwitch.switchCompany()

        } else{
            adminSwitch.switchToAdmin();
        }
    });


    //Catch AjaxAuthenticationListener response
    $(document).ajaxError(function (event, jqXHR) {
        if (401 === jqXHR.status || 403 === jqXHR.status) {
            window.location.href='/'
        }
    });
});

var adminSwitch = {
    company: (user_company) ? user_company.ID : null,
    openPopup: function(message){
        $(".overlay").show();
        if(message){
            $("#confirm_switch_admin .popup-message").text(message);
        }
        else{
            $("#confirm_switch_admin .popup-message").text();
        }
        $("#confirm_switch_admin").show();
    },
    switchCompany : function()
    {
        $(".overlay").hide();
        $("#confirm_switch_admin").hide();
        window.location.href =  Routing.generate('partner-switch', {company: this.company});
    },
    switchToAdmin : function()
    {
        $(".overlay").hide();
        $("#confirm_switch_admin").hide();
        if(Routing['routes_']['en__RG__admin-switch-back'] || Routing['routes_']['fr__RG__admin-switch-back']){
            window.location.href =  Routing.generate('admin-switch-back');
        }
    }
};

function sortTable(table, col, reverse) {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
        tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
        i;
    reverse = -((+reverse) || -1);
    tr = tr.sort(function (a, b) { // sort rows
        return reverse // `-1 *` if want opposite order
            * (a.cells[col].textContent.trim() // using `.textContent.trim()` for test
                    .localeCompare(b.cells[col].textContent.trim())
            );
    });
    for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
}


function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

function sqlToJsDate(sqlDate){
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    var sqlDateArr1 = sqlDate.split("-");
    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
    var sYear = sqlDateArr1[0];
    var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
    var sqlDateArr2 = sqlDateArr1[2].split(" ");
    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
    var sDay = sqlDateArr2[0];
    var sqlDateArr3 = sqlDateArr2[1].split(":");
    //format of sqlDateArr3[] = ['hh','mm','ss.ms']
    var sHour = sqlDateArr3[0];
    var sMinute = sqlDateArr3[1];
    var sqlDateArr4 = sqlDateArr3[2].split(".");
    //format of sqlDateArr4[] = ['ss','ms']
    var sSecond = sqlDateArr4[0];
    var sMillisecond = sqlDateArr4[1];

    function pad(s) { return (s < 10) ? '0' + s : s; }

    inputFormat = new Date(sYear,sMonth,sDay);
    var d = new Date(inputFormat);
    return [pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/');
}

function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    if (text != null)
        return text.replace(/[&<>"']/g, function(m) { return map[m]; });
    else
        return text;
}

function validateEmail(email) {
    var filter = /^(.)+@(.)+\.(.)+$/;
    if (filter.test(email)) {
        return true;
    } else {
        return false;
    }
}


(function($){
    $.fn.serializeObject = function(){

        var self = this,
            json = {},
            push_counters = {},
            patterns = {
                "validate": /^[a-zA-Z_][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
                "key":      /[a-zA-Z0-9_]+|(?=\[\])/g,
                "push":     /^$/,
                "fixed":    /^\d+$/,
                "named":    /^[a-zA-Z0-9_]+$/
            };


        this.build = function(base, key, value){
            base[key] = value;
            return base;
        };

        this.push_counter = function(key){
            if(push_counters[key] === undefined){
                push_counters[key] = 0;
            }
            return push_counters[key]++;
        };

        $.each($(this).serializeArray(), function(){

            // skip invalid keys
            if(!patterns.validate.test(this.name)){
                return;
            }

            var k,
                keys = this.name.match(patterns.key),
                merge = this.value,
                reverse_key = this.name;

            while((k = keys.pop()) !== undefined){

                // adjust reverse_key
                reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');

                // push
                if(k.match(patterns.push)){
                    merge = self.build([], self.push_counter(reverse_key), merge);
                }

                // fixed
                else if(k.match(patterns.fixed)){
                    merge = self.build([], k, merge);
                }

                // named
                else if(k.match(patterns.named)){
                    merge = self.build({}, k, merge);
                }
            }

            json = $.extend(true, json, merge);
        });

        return json;
    };



})(jQuery);


function hasRole(role) {
    if(typeof user_roles === 'object'){
        user_roles = $.map(user_roles, function(value, index) {
            return [value];
        });
    }
    if(user_roles.length > 0){
        return (user_roles.indexOf(role)>=0);
    }
    return false;
}

function trimArray(arr)
{
    for(i=0;i<arr.length;i++)
    {
        arr[i] = arr[i].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    }
    return arr;
}

function simpleCheckEmail(email) {
    var emailsArray = email.split(';');

    return emailsArray.every(function (email) {
        var filter = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
        return filter.test(email);
    });
}
function validateInteger(number) {
    // Trim the input value and store it
    var value = number.trim();

    // Return true if the value is a non-empty, valid integer (including 0)
    return value !== '' && !isNaN(value) && Number.isInteger(parseFloat(value));
}


function getStates(country, element){
    if(!country){
        var options = '<option value="">'+ translate['Select State'] +'</option>';
        $(element).html(options);
        return;
    }
    $.ajax({
        url: baseUrl+'api/country/'+country+'/states',
        dataType:'json',
    }).done(function(response){
        var options = '<option value="">'+ translate['Select State'] +'</option>';
        for(var state in response){
            options+="<option value='"+response[state].ID+"'>"+response[state].name+"</option>";
        }
        $(element).html(options);
        var event = new CustomEvent('state-render-done');
        window.dispatchEvent(event);
    })

}

function val2key(val,array){
    for (var key in array) {
        if(array[key] == val){
            return key;
        }
    }
    return false;
}

function ltrim(str, charlist) {
    charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '$1');
    var re = new RegExp('^[' + charlist + ']+', 'g');
    return (str + '').replace(re, '');
}


var emailRegex = new RegExp('[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?');

if($.validator){
    $.validator.methods.email = function( value, element ) {

        return this.optional( element ) || emailRegex.test( value );
    };
}



function searchApiData(type) {
    var data = {};
    if(pageConfig[type].search){
        data.search = pageConfig[type].search;
        data.filter = pageConfig[type].filter;
        pageConfig[type].page = 1;
        data.page = pageConfig[type].page;
        data.limit = pageConfig[type].limit;
        data.status = pageConfig[type].status;
        data.order = pageConfig[type].order;
        data.dca = pageConfig[type].dca
    }
    if(pageConfig[type].filter){
        data.search = pageConfig[type].search;
        data.filter = pageConfig[type].filter;
        pageConfig[type].page = 1;
        data.page = pageConfig[type].page;
        data.limit = pageConfig[type].limit;
        data.status = pageConfig[type].status;
        data.order = pageConfig[type].order;
        data.dca = pageConfig[type].dca
    }

    if(apiCall){
        apiCall.abort();
    }
    apiCall = $.ajax({
        url:pageConfig[type].searchUrl,
        data:data,
        type:"POST",
        dataType:'json',
        beforeSend:function(){
            $(pageConfig[type].loadMoreBtn).addClass('hidden');
            $(pageConfig[type].loadMoreBtn).next('.loading').removeClass('hidden')
            $("#"+pageConfig[type].table+' .no-result').remove(); //removes no-result
            $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').removeClass('hidden');

            $("#"+pageConfig[type].table).find('tbody').html('');


        },
        success: function(data, textStatus, xhr) {
            if (typeof data.count !== 'undefined') {
                window.total = data.count;
            }
            if (typeof data.page !== 'undefined') {
                window.devicesPerPage = data.perPage;
            }
            $(pageConfig[type].loadMoreBtn).next('.loading').addClass('hidden');

            var no_of_pages = Math.ceil(data.count / data.perPage);
            if(data.page+1 > no_of_pages || data.items.length === 0){
                $(pageConfig[type].loadMoreBtn).addClass('hidden');
            } else{
                $(pageConfig[type].loadMoreBtn).removeClass('hidden');
            }

            $("#"+pageConfig[type].table+" tbody").html(data.template);

            var event = new CustomEvent('ajax-search-complete-'+type);
            window.dispatchEvent(event)
            $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').addClass('hidden');


        },
    }).fail(function(jqXHR, textStatus, errorThrowsn){
        var colspan = $("#"+pageConfig[type].table+" thead th").length;
        if(textStatus !== 'abort'){
            $("#"+pageConfig[type].table+" tbody").html(
                '<tr><td colspan="'+colspan+'" class="text-center text-danger">' + translate['Error while loading request.'] + '</td></tr>'
            );
        }
        var $pdiv = $(pageConfig[type].loadMoreBtn).parent();
        $(pageConfig[type].loadMoreBtn).addClass('hidden');
        $pdiv.find('.loading').addClass('hidden');
        $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').addClass('hidden');

    })
}


function getApiData(type, append){
    var data = {};
    if(pageConfig[type].page){
        data.page = pageConfig[type].page;
    }
    if(pageConfig[type].limit){
        data.limit = pageConfig[type].limit;
    }
    if(pageConfig[type].order){
        data.order = pageConfig[type].order;
    }
    if(pageConfig[type].status){
        data.status = pageConfig[type].status
    }
    if(pageConfig[type].search){
        data.search = pageConfig[type].search;
        data.filter = pageConfig[type].filter;
    }
    if(pageConfig[type].dca){
        data.dca = pageConfig[type].dca
    }

    if(pageConfig[type].filter){
        data.search = pageConfig[type].search;
        data.filter = pageConfig[type].filter;
    }

    if(apiCall){
        apiCall.abort();
    }

    apiCall = $.ajax({
        url:(pageConfig[type].search) ? pageConfig[type].searchUrl : pageConfig[type].loadMoreUrl,
        data:data,
        type:(pageConfig[type].search) ? 'POST' : 'GET',
        dataType:'json',
        beforeSend:function(){
            $(pageConfig[type].loadMoreBtn).addClass('hidden');
            $(pageConfig[type].loadMoreBtn).next('.loading').toggleClass('hidden')
            $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').toggleClass('hidden');
            if(!append){
                $("#"+pageConfig[type].table).find('tbody').html('');
            }
        },
        success: function(data, textStatus, xhr) {
            if (typeof data.count !== 'undefined') {
                window.total = data.count;
            }
            if (typeof data.perPage !== 'undefined') {
                window.perPage = data.perPage;
            }
            $(pageConfig[type].loadMoreBtn).next('.loading').toggleClass('hidden');
            var no_of_pages = Math.ceil(data.count / data.perPage)
            if(data.page+1 > no_of_pages || data.items.length === 0){
                $(pageConfig[type].loadMoreBtn).addClass('hidden');
            } else{
                $(pageConfig[type].loadMoreBtn).removeClass('hidden');
            }
            if(append){
                if(pageConfig[type].table === 'orders-table'){
                    $("#"+pageConfig[type].table+" tbody.customer_orders_table").append(data.template);
                }else{
                    $("#"+pageConfig[type].table+" tbody").append(data.template);
                }
            } else{
                $("#"+pageConfig[type].table+" tbody").html(data.template);
            }

            var event = new CustomEvent('ajax-complete-'+type);
            window.dispatchEvent(event)
            $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').toggleClass('hidden');
        }
    }).fail(function(jqXHR, textStatus, errorThrowsn){

        var colspan = $("#"+pageConfig[type].table+" thead th").length
        if(textStatus !== 'abort'){
            $("#"+pageConfig[type].table+" tbody").html(
                '<tr><td colspan="'+colspan+'" class="text-center text-danger">' + translate['Error while loading request.'] + '</td></tr>'
            )
        }


        var event = new CustomEvent('ajax-fail-'+type);
        window.dispatchEvent(event)

        var $pdiv = $(pageConfig[type].loadMoreBtn).parent();
        $(pageConfig[type].loadMoreBtn).addClass('hidden');
        $pdiv.find('.loading').toggleClass('hidden');
        $("#"+pageConfig[type].table).prevAll('.table-loader-wrapper').find('.linear-activity').toggleClass('hidden');
    })
}


function solveErrors(form, response) {
    var json = response.responseJSON;
    $(json.form.children).each(function(){
        var prop = Object.keys(this)[0];
        if(this.children && this.children.length > 0){

        }
    })
}


function propName(prop, value) {
    for (var i in prop) {
        if (typeof prop[i] == 'object') {
            if (propName(prop[i], value)) {
                return res;
            }
        } else {
            if (prop[i] == value) {
                res = i;
                return res;
            }
        }
    }
    return undefined;
}


function animateRotate(el) {
    // caching the object for performance reasons
    var $elem = $(el);

    // we use a pseudo object for the animation
    // (starts from `0` to `angle`), you can name it as you want
    $({deg: 0}).animate({deg: 360}, {
        duration: 2000,
        step: function(now) {
            // in the step-callback (that is fired each step of the animation),
            // you can use the `now` paramter which contains the current
            // animation-position (`0` up to `angle`)
            $elem.css({
                transform: 'rotate(' + now + 'deg)'
            });
        }
    });
}


function removeSpaces(text){
    return text.replace(/ /g, '');
}

function getDateWithUTCOffset(inputTzOffset){
    var now = new Date(); // get the current time

    var currentTzOffset = -now.getTimezoneOffset() / 60 // in hours, i.e. -4 in NY
    var deltaTzOffset = inputTzOffset - currentTzOffset; // timezone diff

    var nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch
    var deltaTzOffsetMilli = deltaTzOffset * 1000 * 60 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)
    var m = new Date(nowTimestamp + deltaTzOffsetMilli) // your new Date object with the timezone offset applied.

    var dateString =
        ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
        ("0" + m.getUTCDate()).slice(-2) + "/" +
        m.getUTCFullYear();


    return dateString;
}

function formatNumberWithCommas(nStr) {
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

function sameEmail(email) {
    var emailsArray = email.split(';');
    var object = {};
    var result = [];

    emailsArray.forEach(function (item) {
        if(!object[item])
            object[item] = 0;
        object[item] += 1;
    })

    for (var prop in object) {
        if(object[prop] >= 2) {
            result.push(prop);
        }
    }

    return result.length > 0;
}

// Get Cookie
function getCookie(name) {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

// Set Cookie
function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function changeLanguage(chngLang, curLang) {

    let protocol = window.location.protocol + '//';
    let host = window.location.hostname;
    let port = window.location.port ? ':' + window.location.port : '';
    let lang = '/' + chngLang.toLowerCase();
    let path = window.location.pathname;
    let hash = window.location.hash;
    let url = '';

    setCookie('lang', chngLang, 365);

    if(curLang !== 'en'){
        path = path.substr(3);
    }

    if(chngLang === 'en'){
        url = protocol + host + port + path + hash;
    }else{
        url = protocol + host + port + lang + path + hash;
    }

    window.location.href = url;
}

function switchUserCompany(e) {
    $("#confirm_switch_user_company .popup-message").html('<h5>' + translate['Are you sure you want to switch company?'] + '</h5>');
    $("#confirm_switch_user_company").show();
    $(".overlay").show();
    $("#confirm_switch_user_company").attr('data-url', e.target.getAttribute('data-url'))
}

$("#confirm_switch_user_company .js-confirm").on('click', function (e) {
    var url = e.target.closest('#confirm_switch_user_company').getAttribute('data-url');

    if (url) {
        window.location.href = url;
    }
});

function removeUnusedLocalStorageTableNames(localStorageTableNames) {
    let existingLocalStorageTableNames = JSON.parse(localStorage.getItem("localStorageTableNames"));
    if (existingLocalStorageTableNames) {
        $.each(existingLocalStorageTableNames, function (type, data) {
            if($.inArray(type, localStorageTableNames) === -1) {
                delete existingLocalStorageTableNames[type];
            }
        });
    }
    localStorage.setItem('localStorageTableNames', JSON.stringify(existingLocalStorageTableNames));
}

function initLocalStorageTableNamesAndTableData(initTableType) {
    removeUnusedLocalStorageTableNames(localStorageTableNamesAllowed);

    let existingLocalStorageTableNames = JSON.parse(localStorage.getItem("localStorageTableNames"));
    let search = false;
    let len = $.map(existingLocalStorageTableNames, function(n, i) { return i; }).length;

    if (existingLocalStorageTableNames && len) {
        $.each(existingLocalStorageTableNames, function (type, data) {
            if (typeof pageConfig[type] === 'undefined') {
                return;
            }
            if (data.search) {
                search = true;
            }
            if (typeof tempConfig[type] === 'undefined') {
                tempConfig[type] = {};
            }
            tempConfig[type].page = data.page;
            tempConfig[type].limit = data.limit;

            let limit = data.page * data.limit;

            pageConfig[type].page = 1;
            pageConfig[type].limit = limit;
            pageConfig[type].search = data.search;
            pageConfig[type].status = data.status;
            pageConfig[type].order = data.order;

            // init main table search/filter/order input values
            if (type === initTableType) {
                let search = data.search;
                let status = data.status;
                let order = data.order;

                // init search input
                if (search.length) {
                    $(pageConfig[type].inputFieldIDS.search).val(search);
                }

                // init status (filter inputs)
                if (Object.keys(status).length) {
                    if (pageConfig[type].inputFieldIDS.hasOwnProperty('status')) {
                        if (typeof status['view'] !== 'undefined') {
                            $(pageConfig[type].inputFieldIDS.status.view).val(status['view'])
                        }
                        if (typeof status['orderingMethod'] !== 'undefined') {
                            $(pageConfig[type].inputFieldIDS.status.orderingMethod).val(status['orderingMethod'])
                        }
                        if (typeof status['new'] !== 'undefined') {
                            let checked = Boolean(status['new']);
                            $(pageConfig[type].inputFieldIDS.status.new).prop('checked', checked)
                        }
                        if (typeof status['offline'] !== 'undefined') {
                            let checked = Boolean(status['offline']);
                            $(pageConfig[type].inputFieldIDS.status.offline).prop('checked', checked)
                        }
                        if (typeof status['deleted'] !== 'undefined') {
                            let checked = Boolean(status['deleted']);
                            $(pageConfig[type].inputFieldIDS.status.deleted).prop('checked', checked)
                        }
                    }
                }

                // init order (ASC/DESC)
                if (order) {
                    $("#"+pageConfig[type].table+" .sort").each(function(){
                        $(this).removeClass('sort-up').removeClass('sort-down');
                    });

                    let className = 'sort-up';
                    let selectDataName = "";
                    for (var key in order){
                        if (order[key] == "desc") {
                            className = 'sort-down';
                        }
                        selectDataName += '[data-name="' + key + '"]';
                    }

                    if (selectDataName !== '') {
                        $(selectDataName + ' a.sort').addClass(className)
                    }
                }
            }
        });

        // init local storage table names and data that are required but don't exists
        $.each(localStorageTableNames, function (index, tableName) {
            if(typeof existingLocalStorageTableNames[tableName] === 'undefined') {
                existingLocalStorageTableNames[tableName] = localeStorageTableDefaultConfig;
            }
        });

        localStorage.setItem('localStorageTableNames', JSON.stringify(existingLocalStorageTableNames));

    } else {

        // init all required local storage table names and date
        let localStorageTableNamesTemp = {};
        $.each(localStorageTableNames, function (index, tableName) {
            localStorageTableNamesTemp[tableName] = localeStorageTableDefaultConfig
        });

        localStorage.setItem('localStorageTableNames', JSON.stringify(localStorageTableNamesTemp));
    }
    // init table data
    if (search) {
        searchApiData(initTableType)
    } else {
        getApiData(initTableType);
    }
}

function initSearchTableEvent(search, tableType, tableHTMLID) {
    ajaxInProgress = true;
    //clear table body
    $(tableHTMLID).find('tbody').html('');

    pageConfig[tableType].page = 1;
    pageConfig[tableType].search = null;
    pageConfig[tableType].limit = default_per_page;

    let existingLocalStorageTableNames = JSON.parse(localStorage.getItem("localStorageTableNames"));

    if(typeof existingLocalStorageTableNames[tableType] === 'undefined') {
        existingLocalStorageTableNames[tableType] = localeStorageTableDefaultConfig;
    }

    existingLocalStorageTableNames[tableType].page = 1;
    existingLocalStorageTableNames[tableType].search = '';
    existingLocalStorageTableNames[tableType].limit = default_per_page;

    // init table body (search / get)
    if(search.length > 0){
        pageConfig[tableType].search = search;
        existingLocalStorageTableNames[tableType].search = search;
        searchApiData(tableType);
    } else{
        getApiData(tableType);
    }

    localStorage.setItem('localStorageTableNames', JSON.stringify(existingLocalStorageTableNames));
}

function initLoadMoreTableEvent(tableType) {
    ajaxInProgress = true;

    pageConfig[tableType].page++;

    let existingLocalStorageTableNames = JSON.parse(localStorage.getItem("localStorageTableNames"));
    if (existingLocalStorageTableNames) {
        if (typeof existingLocalStorageTableNames[tableType] !== 'undefined') {
            existingLocalStorageTableNames[tableType].page++;
        }
    }

    localStorage.setItem('localStorageTableNames', JSON.stringify(existingLocalStorageTableNames));

    getApiData(tableType, true);
}

function ajaxComplete(table) {
    ajaxInProgress = false;
    $.each(tempConfig, function (type, tableName) {
        if (typeof pageConfig[type] !== 'undefined') {
            window.perPage = tableName.limit;
            pageConfig[type].page = tableName.page;
            pageConfig[type].limit = tableName.limit;
        }
    });
    tempConfig = {};

    if (table) {
        $(table).removeClass('stacktable');

        var tableClass = table.substring(1) + '-js';
        $("." + tableClass).remove();
        $(table).cardtable({myClass: tableClass});

        var event = new CustomEvent('cardtable-complete');
        window.dispatchEvent(event)
    }
}

function printReleafApi(apiKey, btn) {
    if (apiKey) {
        $.ajax({
            url: baseUrl + 'api/printreleaf/check',
            data: {'key': apiKey},
            type: 'POST',
            dataType: 'JSON',
            beforeSend: function() {
                btn.prop('disabled', true);
                $(".printreleaf-message").removeClass('red green').html('');
            },
            success: function (data) {
                btn.prop('disabled', false);
                let text, clss;
                if (data.success) {
                    clss = 'green';
                    text = translate['PrintReleaf API key is valid.'];
                } else {
                    clss = 'red';
                    text = translate['PrintReleaf API key is not valid.'];
                }

                $(".printreleaf-message").addClass(clss).html(text);
            }
        });
    }
}


function getHeaderNotificationErrorsDropdown()
{
    $.ajax({
        url: baseUrl + 'api/notification-errors-dropdown',
        data: {'errorsPage': errorsPage},
        type: 'POST',
        dataType: 'JSON',
        success: function (data) {
            if (data) {
                // append dropdown html
                if (data.template) {
                    $('#js-loader-notification-menu').remove();
                    $('#error-notifications-dropdown').append(data.template);
                }

                // set global notification errors total count in header red circle
                if (data.errorNotificationCount) {
                    var totalNotificationsErrorsCount = '<span id="js-dropdown-span-all-count" class="error-notification-count">'+data.errorNotificationCount+'</span>';
                    $('#error-notification-icon').html(totalNotificationsErrorsCount)
                }
            }

        }
    });

}
